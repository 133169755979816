/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "ngx-bootstrap/datepicker/bs-datepicker";

/* You can add global styles to this file, and also import other style files */
@import "material-icons/iconfont/material-icons.css";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import "node_modules/@aws-amplify/ui-angular/theme.css";


body {
    font-size: 1.4rem !important;
}

.modal {
    z-index: 1000 !important;
}

.d-none {
    display: none !important;
}

.menuToggle {
    min-width: 50px;
    padding: 5px 7px;
    line-height: 40px;
    text-align: center;
    color: #000 !important;
}

.btn {
    font-size: 1.4rem !important;
    padding: 0.5rem 0.5rem !important;
}

.form-control {
    font-size: 1.5rem !important;
}
table {
    th,
    td,
    .form-control {
        font-size: 1rem !important;
    }
}

textarea.form-control {
    resize: none !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

@media print {
    body {
        -webkit-print-color-adjust: exact;
    }
    .error-custom {
        display: none;
    }
    .not-print {
        display: none;
    }
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color:rgb(66, 134, 244); 
  }
  
  .mat-radio-button.mat-accent .mat-radio-inner-circle{
    color:rgb(66, 134, 244);
    background-color:rgb(66, 134, 244) ;
  }
  
  .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
      background-color:rgb(255, 37, 37,.26);
  }


  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-icon-color: rgb(66, 134, 244) !important;
    --mdc-radio-selected-focus-icon-color: rgb(66, 134, 244) !important;
    --mdc-radio-selected-hover-icon-color: rgb(66, 134, 244) !important;
    --mdc-radio-selected-icon-color: rgb(66, 134, 244) !important;
    --mdc-radio-selected-pressed-icon-color: rgb(66, 134, 244) !important;
    --mat-radio-ripple-color: #000;
    --mat-radio-checked-ripple-color: rgb(66, 134, 244) !important;
  }
:root {
    //--amplify-primary-color: red;
    --primary-color: #0d6efd;
    --secondary-color: #64868e;
    --primary-gradient: linear-gradient(145deg, var(--primary-color), var(--secondary-color));
}

.banner-img {
    width: 40%;
}

/*.amplify-button[data-variation='link']:hover {
    background-color: red;
    text-decoration: underline;
}

.amplify-button {
    background-color: #ff5c50 !important;
}*/
